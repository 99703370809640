import React, { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { tratarLink } from "../../../helpers/tratarInfoCarros";
import ImageRenderer from "../../../tools/images/ImageRenderer";
import { yScrollContext } from "../../../tools/restoreScroll/yScroll";
import css from "../../noticias/noticia.module.css";
import cssAH from "../albumHome/albumHome.module.css";
import imgNone from "../../../../assets/static_img/ind_media.webp";
import Spacer from "../Spacer";

export default function CardRecentes({ data }) {
  const yScroll = useContext(yScrollContext);
  const [imagem, setImagem] = useState("");
  const [srcSet, setSrcSet] = useState("");
  useEffect(() => {
    if (data.Ficheiros.length !== 0) {
      const ImagemPrincipal = data.Ficheiros.filter((carro) => {
        return carro.Principal === true;
      });
      setImagem(
        ImagemPrincipal[0] === undefined
          ? {
              Ficheiro: imgNone,
              Indice: 6,
              Principal: true,
              Ordenador: "",
            }
          : ImagemPrincipal[0]
      );
    } else {
      setImagem({
        Ficheiro: imgNone,
        Indice: 6,
        Principal: true,
        Ordenador: "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (imagem.Ficheiro) {
      const imagemSeloGrande = imagem.Ficheiro;
      const imagemSeloMedio = imagem.Ficheiro.replace("-6.", "-4.");
      const imagemSeloPequeno = imagem.Ficheiro.replace("-6.", "-4.");

      const imageVariants = [
        {
          srcSet: imagemSeloPequeno,
          media: "(max-width: 350px)",
        },
        {
          srcSet: imagemSeloMedio,
          media: "(max-width:991px)",
        },
        {
          srcSet: imagemSeloGrande,
        },
      ];

      setSrcSet(imageVariants);
    }
  }, [imagem]);

  const location = tratarLink(
    data.Marca.Nome,
    data.Viatura === "barcos" ? data.Motorizacao : data.Modelo.Nome,
    data.CodViatura,
    data.Viatura
  );

  return (
    <>
      <Col className={`${cssAH.boxPadding}`}>
        <Col className={` position-relative `}>
          <Link
            to={location}
            state={{
              backward: {
                yscroll: yScroll,
              },
            }}
          >
            <Col xs={12}>
              <>
                {srcSet !== "" && (
                  <ImageRenderer
                    divName={css.imgbEqualNoticia}
                    //   className={`${css.imagem}`}
                    url={srcSet[2].srcSet}
                    thumb={srcSet[0].srcSet}
                    alt={`${data.Marca.Nome} ${data.Modelo.Nome}`}
                    sources={srcSet}
                  />
                )}
              </>
            </Col>
            <Col
              xs={12}
              className={` ${css.paddingNoticiasClientes} ${css.NoticiasDestaques}`}
            >
              <Spacer clasName="col-12 col nopadding" height="30px" />
              <Col
                xs={12}
                style={{ textAlign: "center" }}
                className={` ${css.noticiasClientesTitulo}  `}
              >
                {data.Marca.Nome}
              </Col>
              <Col
                xs={12}
                style={{ textAlign: "center" }}
                className={` ${css.noticiasClientesData}  `}
              >
                {data.Viatura === "barcos"
                  ? data.Motorizacao
                  : data.Modelo.Nome}
              </Col>
            </Col>
          </Link>
        </Col>
      </Col>
    </>
  );
}
