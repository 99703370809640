import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Modal } from "react-bootstrap";
import { A, renderEmail, Span } from "react-html-email";
import * as apiEmail from "../../../api/apiEmail";
import useScriptRecaptcha from "../../hooks/useScriptRecaptcha";
import * as Yup from "yup";
import { Formik } from "formik";
import css from "../../pages/oficina/oficina.module.css";

import { theme } from "../../tools/email/templates/Signup/theme";
import { WithDebug } from "../../tools/email/templates/HtmlEmail";

import { ReactComponent as Enviar } from "../../../assets/icons/modal/enviar.svg";
import { ReactComponent as Fechar } from "../../../assets/icons/modal/fechar.svg";
import cssM from "../../pages/ficha/message/messageForm.module.css";
import cssModal from "../../modal/modalPartilhar.module.css";

const logo = "/assets/img/logo.svg";

const data = new Intl.DateTimeFormat("pt", {
  dateStyle: "long",
  timeStyle: "medium",
}).format(new Date());

const ValuesSchema = Yup.object().shape({
  telemovel: Yup.string()
    .max(9, "O Telemovel só pode ter 9 digitos")
    .required("Por favor insira o número de Telemovel"),
  terms: Yup.bool().required().oneOf([true]),
});

const EmailLigame = ({ campos, infoJson, camposEmail }) => {
  return (
    <>
      <WithDebug
        theme={theme}
        title={camposEmail.assunto}
        emailHeaderHref={`http://${infoJson.Stand.Website}`}
        emailHeaderText={() => {
          return (
            <>
              <Span>
                <h1 style={theme.emailHeader.emailHeaderText}>NOVA MENSAGEM</h1>
                <A
                  href={`http://${infoJson.Stand.Website}`}
                  textDecoration={theme.emailHeader.a.textDecoration}
                  style={theme.emailHeader.a}
                >
                  em {`${infoJson.Stand.Website}`}
                </A>
              </Span>
            </>
          );
        }}
        emailHeaderLogo={logo}
        bodyHeaderText={() => {
          return (
            <>
              Recebeu uma mensagem relacionada com <b>{camposEmail.assunto}</b>
            </>
          );
        }}
        bodyContentComponent={() => {
          return (
            <>
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>MENSAGEM ENVIADA</b> em{" "}
                {data}
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Telemovel: <b>{campos.telemovel}</b>
              </p>
            </>
          );
        }}
        bodyFooterText={() => {
          return (
            <>
              <p style={theme.emailBody.bodyFooter.spanNoMargin}>
                Telef:<b>{campos.telemovel}</b>
              </p>
            </>
          );
        }}
        debug={false}
      />
    </>
  );
};

export default function ModalLigueMe({ allinfo, infoJson, show, setShow }) {
  const [camposEmail, setCamposEmail] = useState({
    assunto: `Contacto de Potencial Cliente - Liga-me`,
    user: `${process.env.REACT_APP_EMAIL_USERNAME}`,
    pass: `${process.env.REACT_APP_EMAIL_PASSWORD}`,
    emailEmpresa: "",
    corpoEmpresa: "",
    nomeEmpresa: `${infoJson.Stand.Nome}`,
    "g-recaptcha-response": "",
    SECRET_KEY: process.env.REACT_APP_RECAPCHA_SECRETKEY,
  });

  useScriptRecaptcha();

  useEffect(() => {
    camposEmail["emailEmpresa"] = allinfo.Email;
    setCamposEmail(camposEmail);
  }, [allinfo, camposEmail]);

  const handleSubmit = (values) => {
    const emailHTML = renderEmail(
      <EmailLigame
        campos={values}
        infoJson={infoJson}
        camposEmail={camposEmail}
      />
    );

    camposEmail["corpoEmpresa"] = emailHTML;
    setCamposEmail(camposEmail);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPCHA_SITEKEY, { action: "submit" })
        .then((token) => {
          camposEmail["g-recaptcha-response"] = token;
          apiEmail.sendMail(camposEmail, infoJson).then((error) => {
            return error.status === 201
              ? (document.location.href = "/obrigado/")
              : error.status;
          });
        });
    });
  };
  return (
    <Modal
      show={show}
      size="lg"
      fullscreen={"md-down"}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title className={`cor-titulo ${cssModal.titulo}`}>
          Precisa de Ajuda? Nós Ligamos!
        </Modal.Title>
        <div
          onClick={() => setShow(false)}
          className={` ${cssModal.close} close`}
        >
          <Fechar />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formik
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={{
            telemovel: "",
            terms: false,
          }}
          validationSchema={ValuesSchema}
        >
          {({ handleSubmit, touched, getFieldProps, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Col xs={12}>
                <FloatingLabel
                  label="*Telemovel"
                  controlId="formGridTelemovel"
                  className="mb-4 "
                >
                  <Form.Control
                    type="number"
                    maxLength="9"
                    placeholder="Escreva aqui"
                    max="9"
                    {...getFieldProps("telemovel")}
                    isInvalid={!!errors.telemovel && touched.telemovel}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.telemovel}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Form.Group controlId="ModalLigueMe" className="mb-4 ">
                <Form.Label style={{ display: "flex" }}>
                  <Form.Check
                    type="checkbox"
                    {...getFieldProps("terms")}
                    isInvalid={!!errors.terms && touched.terms}
                    label={
                      <>
                        Li e Aceito
                        <a className={` ${css.a}`} href="/privacidade/">
                          os Termos de Utilização e Proteção de Dados
                        </a>
                      </>
                    }
                  />
                </Form.Label>
              </Form.Group>

              <button
                disabled={!values.terms}
                className={` btnForm ${cssM.btn} ${cssM.btnR}  `}
                type="submit"
              >
                <Enviar fill={"#ffffff"} style={{ marginRight: "10px" }} />
                Enviar
              </button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
