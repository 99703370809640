import React from "react";

import { Nav } from "react-bootstrap";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import css from "../header.module.css";
export default function CostumNavLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Nav.Link {...props} as={Link} to={to}>
      <div
        className={` ${match ? ` ${css.active} active` : ""}  ${
          css.boxMenu
        } boxMenuTop`}
      >
        {children}
      </div>
      <span></span>
    </Nav.Link>
  );
}
