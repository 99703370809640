import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  SplitValue,
  tratarAnoMes,
  tratarCombustivel,
  tratarKm,
  tratarLink,
  tratarMotorizacao,
  tratarPreco,
  tratarPrecoPromo,
  tratarValorMensalidade,
} from "../../../helpers/tratarInfoCarros";
import css from "../listagem/carro.module.css";
import cssD from "./destaques.module.css";
import { ReactComponent as Comb } from "../../../../assets/icons/caract/comb.svg";
import { ReactComponent as Calendar } from "../../../../assets/icons/caract/calendar.svg";
import { ReactComponent as Km } from "../../../../assets/icons/caract/km.svg";
import { ReactComponent as Mais } from "../../../../assets/icons/arrow/opcoes.svg";
import cssO from "../listagem/opcoes.module.css";
import TagBanner from "./TagBanner";
import Spacer from "../Spacer";
import MobileDetect from "mobile-detect";
import { CSSTransition } from "react-transition-group";
import OpcoesDestaqueBanner from "./OpcoesDestaqueBanner";
import { Picture } from "react-responsive-picture";

export default function CardBannerDestaque({
  data,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  alltexto,
}) {
  const [imagem, setImagem] = useState("");
  const [srcSet, setSrcSet] = useState("");
  const [opcoes, setOpcoes] = useState(false);
  const [exit, setExit] = useState(true);
  const nodeRef = React.useRef(null);
  useEffect(() => {
    if (data.Ficheiros.length !== 0) {
      const ImagemPrincipal = data.Ficheiros.filter((carro) => {
        return carro.Principal === true;
      });

      setImagem(ImagemPrincipal[0].Ficheiro);
    }
  }, [data]);

  useEffect(() => {
    if (imagem) {
      const imagemSeloGrande = imagem;
      const imagemSeloMedio = imagem.replace("-6.", "-4.");
      const imagemSeloHd = imagem.replace("-6.", "-0.");

      const imageVariants = [
        {
          srcSet: imagemSeloMedio,
          media: "(max-width: 350px)",
        },
        {
          srcSet: imagemSeloGrande,
          media: "(max-width:991px)",
        },
        {
          srcSet: imagemSeloHd,
        },
      ];

      setSrcSet(imageVariants);
    }
  }, [imagem]);

  const CombustivelTxt = tratarCombustivel(data.Combustivel.Nome);
  const location = tratarLink(
    data.Marca.Nome,
    data.Viatura === "barcos" ? data.Motorizacao : data.Modelo.Nome,
    data.CodViatura,
    data.Viatura
  );
  const ano = tratarAnoMes(data.Ano, data.Mes);
  const km = tratarKm(data.Km, true);
  const PrecoLimpo = tratarPreco(data.Preco);
  const PrecoPromoLimpo = tratarPrecoPromo(data.PrecoPromo);
  const ValorMensalidadeLimpo = tratarValorMensalidade(data.ValorMensalidade);
  const vnSemExtras = tratarPreco(data.VNSemExtras);
  const Motorizacao = tratarMotorizacao(
    data.Viatura === "barcos" ? data.Motorizacao : data.VersaoAlternatica,
    data.Motorizacao
  );

  const PrecoArray = SplitValue(PrecoLimpo);
  const PrecoPromoArray = SplitValue(PrecoPromoLimpo);

  let userAgent;
  let deviceType;
  userAgent = navigator.userAgent;
  const md = new MobileDetect(userAgent);
  if (md.tablet()) {
    deviceType = "tablet";
  } else if (md.mobile()) {
    deviceType = "mobile";
  } else {
    deviceType = "desktop";
  }
  return (
    <>
      <Col
        xs={12}
        style={{ marginTop: "0px" }}
        onMouseLeave={() => {
          exit && setOpcoes(false);
        }}
      >
        <div>
          <Link to={location}>
            <div
              className={`${cssD.imgbEqualBanner} ${css.imgbEqual} ${cssD.imgbBanner}`}
            >
              {srcSet !== "" && (
                <Picture
                  alt={`${data.Marca.Nome} ${data.Modelo.Nome}`}
                  sources={srcSet}
                />
              )}
            </div>
          </Link>
          <div className={`${cssD.BoxBanner}`}>
            <Link to={location}>
              <div className={`${cssD.tituloBanner} titulo-page `}>
                {alltexto.Titulo !== ""
                  ? alltexto.Titulo
                  : "Viaturas em Destaque"}
              </div>
            </Link>
            <Link to={location}>
              <TagBanner
                vendido={data.Vendido}
                reservado={data.Reservado}
                tagImg={data.TagImg}
                precoPromo={data.PrecoPromo}
                dispoBreve={data.DisponivelBrevemente}
                ivaDedutivel={data.PrecoIvaDedutivel}
                ivaDescriminado={data.IvaDiscriminado}
              />
            </Link>
            <Link to={location}>
              <div className={`${cssD.MarcaBanner}`}>
                <span>{data.Marca.Nome} </span>
                <br className=" d-md-none"></br> {data.Modelo.Nome}{" "}
                {Motorizacao}
              </div>
            </Link>
            <div className={`${cssD.flexInfoBanner}`}>
              <Link to={location}>
                <div className={cssD.listagem_specs_item_banner}>
                  <div>
                    <Calendar height="27.2px" width="27.2px" stroke="#fff" />
                  </div>
                  <div>
                    <div> Registo</div>
                    <div> {ano}</div>
                  </div>
                </div>
              </Link>
              <Link to={location}>
                <div className={cssD.listagem_specs_item_banner}>
                  <div>
                    <Comb height="29px" width="23px" stroke="#fff" />
                  </div>
                  <div>
                    <div> Combustível</div>
                    <div> {CombustivelTxt}</div>
                  </div>
                </div>
              </Link>
              <Link to={location}>
                {km !== "" && (
                  <div className={cssD.listagem_specs_item_banner}>
                    <div>
                      <Km height="26px" width="24px" fill="#fff" />
                    </div>
                    <div>
                      <div> Quilómetros</div>
                      <div> {data.Vendido !== true && km ? km : "-"}</div>
                    </div>
                  </div>
                )}
              </Link>
              <Link to={location}>
                <div className={cssD.btnPrice}>
                  {data.Vendido !== true ? (
                    <>
                      {data.NaoExportarPreco !== true &&
                      PrecoPromoLimpo !== "" ? (
                        <Col
                          xs={12}
                          className={`${css.campanha} ${css.nocampanha}`}
                        >
                          Campanha
                        </Col>
                      ) : (
                        <Col
                          xs={12}
                          className={`${css.nocampanha} ${css.nocampanhaColor}  `}
                        >
                          Preço
                        </Col>
                      )}
                    </>
                  ) : (
                    <>
                      <Col
                        xs={12}
                        className={`${css.nocampanha} ${css.nocampanhaColor}  `}
                      >
                        Viatura
                      </Col>
                    </>
                  )}

                  <Row>
                    <Col
                      xs={
                        data.NaoExportarMensalidade !== true &&
                        ValorMensalidadeLimpo !== ""
                          ? 6
                          : 12
                      }
                      className={`boxPrecoFicha  ${css.boxPreco} ${cssD.flexPreco}`}
                    >
                      {data.Vendido !== true ? (
                        <>
                          {data.NaoExportarPreco !== true &&
                          PrecoPromoLimpo !== "" ? (
                            <>
                              <span>{PrecoPromoArray[0]}</span>
                              {PrecoPromoArray[1]}
                              {PrecoPromoArray[2]}
                            </>
                          ) : data.NaoExportarPreco !== true &&
                            PrecoLimpo !== "" ? (
                            <>
                              <span>{PrecoArray[0]}</span>
                              {PrecoArray[1]}
                              {PrecoArray[2]}
                            </>
                          ) : (
                            <> Sob/Consulta</>
                          )}
                        </>
                      ) : (
                        <>Vendida</>
                      )}
                    </Col>
                    {data.Vendido !== true && (
                      <>
                        {data.NaoExportarMensalidade !== true &&
                          ValorMensalidadeLimpo !== "" && (
                            <Col
                              xs={6}
                              className={`${css.nocampanha} ${cssD.nocampanhaBanner} `}
                            >
                              DESDE <br></br>
                              <b>{ValorMensalidadeLimpo}/MÊS</b>
                            </Col>
                          )}
                      </>
                    )}
                  </Row>

                  {data.Vendido !== true &&
                  data.NaoExportarPreco !== true &&
                  PrecoPromoLimpo !== "" ? (
                    <Col
                      xs={12}
                      className={`${css.nocampanha} ${css.nocampanhaColor}  `}
                    >
                      Antes <b>{PrecoLimpo}</b>
                    </Col>
                  ) : vnSemExtras !== "" ? (
                    <Col
                      xs={12}
                      className={`${css.nocampanha} ${css.nocampanhaColor}  `}
                    >
                      Antes <b>{vnSemExtras}</b>
                    </Col>
                  ) : (
                    <></>
                  )}
                </div>
              </Link>
              <div className={`${cssD.divCompare}  position-relative`}>
                <div
                  onMouseEnter={() => {
                    setOpcoes(true);
                  }}
                  onClick={() => {
                    deviceType !== "desktop" && setOpcoes(!opcoes);
                  }}
                  className={`${cssD.Mais}  ${
                    opcoes ? "MaisBanner ative" : "MaisBanner"
                  } `}
                >
                  <Mais />
                </div>
                <CSSTransition
                  nodeRef={nodeRef}
                  in={opcoes}
                  unmountOnExit
                  timeout={300}
                  classNames={{
                    enterActive: `${cssO.opcoesEnter}`,
                    enterDone: `${cssO.opcoesBannerEnterActive}`,
                    exit: `${cssO.opcoesExit}`,
                    exitActive: `${cssO.opcoesExitActive}`,
                  }}
                >
                  <OpcoesDestaqueBanner
                    nodeRef={nodeRef}
                    carro={data}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    infoJson={infoJson}
                    setState={setExit}
                  />
                </CSSTransition>{" "}
              </div>
            </div>
            {!infoJson.Banner.Existe && <Spacer height="70px" />}
          </div>
        </div>
      </Col>
    </>
  );
}
